class Wishlist {
  constructor() {
    $(document).ready(() => {
      $("[data-ui-trigger=add-to-wishlist]").on("click", (e) => {
        e.preventDefault();
        this.addToWishlist(event.currentTarget);
      });

    });
  }

  addToWishlist(element) {
    const listing_ID = $(element).attr("data-listing-id");
    if (! listing_ID)
      return;

    let action = 'add';
    let csrf_token = Cookies.get('csrftoken');

    if ($(element).attr("data-remove")) {
      action = 'remove';
    }


    $.ajax({
        url: window.LQFY.routes.wishlist,
        method: 'POST',
        data: {
            csrfmiddlewaretoken: csrf_token,
            listing: listing_ID,
            action: action,
        },
        headers: {
            'X-CSRFToken': csrf_token,
        }
    }).done((data) => {
      if (data.status != 'ok') {
        return;
      }

      this.updateCount(data.count);
      if (action == 'add') {
        $(element).addClass("product__favorite--active");
        $(element).attr("data-remove", true);
      } else {
        $(element).removeClass("product__favorite--active");
        $(element).removeAttr("data-remove");
      }
    });
  }

  updateCount(count) {
    $("[data-ui-bind=wishlist-count]").html("(" + count + ")");
  }

  remove(listing_ID) {
    $(`[data-listing-type='wishlist'][data-listing-id='${listing_ID}']`).remove();
  }
}

export default Wishlist;
