import $ from "jquery";
import filters from "./filterList";

class MediaFilter
{
  constructor(rootNode) {
    $(document).ready(() => {
      this.rootNode = rootNode || document.querySelector("[data-media-filter]");

      if (!this.rootNode) {
        return;
      }

      this.rootNode.mediaFilter = this;

      this.mainImgContainer = $(this.rootNode).find("[data-media-filter-main-img-container]");
      this.mainImgObj = false;
      this.activeFilter = false;
      this.activeFilterIdentifier = false;
      this._originalImgSrc = false;
      this._onSave = this.nop;
      this.autoLoad();
    });
  }

  nop() {
    return;
  }

  /**
   * Calls the registered onSave callback.
   * @return {Void}
   */
  onSave() {
    if (this.activeFilter)
      this._onSave({
        filter: this.activeFilter,
        imgSrc: this.mainImgObj.src
      });
    else
      this._onSave(false)

    this.hide();

  }

  /**
   * Auto loads and hooks appropraite items.
   * @return {Void}
   */
  autoLoad() {
    $(this.rootNode).find('[data-media-filter-close]').on('click', this.hide.bind(this));
    $(this.rootNode).find('[data-media-filter-save]').on('click', this.onSave.bind(this));
  }

  /**
   * Hides the media filter window.
   * @return {Void}
   */
  hide() {
    $(this.rootNode).removeClass("active");
  }

  /**
   * Sets the main image preview source.
   * @param {String} imgSrc The image URL.
   * @return {Void}
   */
  setImgSrc(imgSrc, callback) {
    let buffer = '<img src="' + imgSrc + '" data-media-filter-main-img />';

    let img = document.createElement("img");
    if (typeof callback === "function") {
      img.onload = () => {
        callback();
      };
    }
    img.setAttribute("data-media-filter-main-img", "true");
    img.src = imgSrc;

    $(this.mainImgContainer).html("");
    $(this.mainImgContainer).append($(img));

    let imgObj = document.querySelector('[data-media-filter-main-img]');
    this.mainImgObj = imgObj;
    this._originalImgSrc = imgObj.src;
  }

  /**
   * Sets up and renders the filter image boxes.
   * @return {Void}
   */
  setFilters() {
    let baseNode = document.querySelector('#filter-wrapper');
    baseNode.innerHTML = "";

    let imgUrl = $(this.mainImgObj).attr('src');

    for (let i=0; i<filters.length; ++i) {
      let filter = filters[i];

      let el = document.createElement('div');
      if (this.activeFilterIdentifier === false) {
        el.className = (i === 0 ? "filter active" : "filter");
      } else {
        el.className = (filter.identifier === this.activeFilterIdentifier ? "filter active" : "filter");
      }

      let img = document.createElement('img');
      img.src = imgUrl;

      let outImg = document.createElement('img');
      img.onload = function() {
        filterous.importImage(img)
          .applyInstaFilter(filter.identifier).renderHtml(outImg)
      }

      let title = document.createElement('div');
      title.className = "filter-title";
      title.innerText = filter.label;

      el.appendChild(outImg);
      el.appendChild(title);

      const _this = this;
      ((filter) => {
        $(el).on("click", function(e) {
          e.preventDefault();
          $(".filter.active").removeClass("active");
          $(this).addClass("active");
          _this.activateFilter(filter);
        });
      })(filter);

      baseNode.appendChild(el);

    }
  }

  /**
   * Activates a given filter and applies the filering to the images.
   * @param {Object} filter The filter object.
   * @return {Void}
   */
  activateFilter(filter) {
    this.activeFilter = filter;
    this.activeFilterIdentifier = filter.identifier;

    const outImg = document.createElement("img");
    outImg.onload = () => {
      $(this.mainImgContainer).html("");
      $(this.mainImgContainer).append(outImg);
      this.mainImgObj = outImg;
    }

    if (filter.identifier == "normal") {
      outImg.src = this._originalImgSrc;
    } else {
      let inImg = document.createElement("img");
      inImg.onload = () => {
        filterous.importImage(inImg)
          .applyInstaFilter(filter.identifier).renderHtml(outImg);
      };
      inImg.src = this._originalImgSrc;
    }
  }

  _getFilterByIdentifier(identifier) {
    for (let i=0; i<filters.length; ++i) {
      if (filters[i].identifier == identifier)
        return filters[i];
    }
    return false;
  }

  /**
   * Sets the filter window active.
   * @param {String} imgSrc The URL source of the image.
   * @param {Function} onSave The callback function to fire after save is complete.
   * @return {Void}
   */
  toggle(imgSrc, currentFilter, onSaveCb) {
    this._onSave = onSaveCb;
    this.setImgSrc(imgSrc);

    let f;
    if (currentFilter) {
      f = this._getFilterByIdentifier(currentFilter)
    } else {
      f = this._getFilterByIdentifier("normal");
      this.activeFilter = false;
      this.activeFilterIdentifier = false;
    }

    if (f)
      this.activateFilter(f);
    else
      console.warn("The current filter wasn't found.");

    this.setFilters();
    $(this.rootNode).addClass("active");
  }
}

export default MediaFilter;
