import FancyBox from "../vendor/jquery.fancybox";

class Gallery {
  constructor() {
    $(document).ready(() => {
      this.autoLoad()
    });
  }

  /**
   * Hooks gallery toggle elements.
   * @return {Void}
   */
  autoLoad() {
    const _this = this;
    $("[data-gallery-toggle]").on("click", function (e) {
      _this.loadGallery(e, this);
    });

    $("[data-skip-specifications]").on("click", () => this.skipSpecifications());
    //sell-appraisal-upload
  }

  /**
   * Launches the gallery specificed in the [data-gallery-toggle] attribute.
   * @param {Event} e
   * @return {Void}
   */
  loadGallery(e, el) {
    e.preventDefault()
    const _this = this;
    const galleryName = $(el).attr('data-gallery-toggle');
    if (!galleryName)
      return;

    const galleryData = window[`gallery_${galleryName}`] || false;
    if (!galleryData)
      return;

    $.fancybox.open(galleryData, {
      loop: false,
      buttons: ['close'],
      infobar: false,
      thumbs: {
        autoStart: true
      },
      afterLoad: function () {
        _this.applyFilter(this);
      }
    });
  }

  applyFilter(imgObj) {
    if (!imgObj.opts.hasOwnProperty("meta") ||
        !imgObj.opts.meta.hasOwnProperty("filter"))
      return;

    if (!imgObj.opts.meta.filter)
      return;

    let filter = imgObj.opts.meta.filter.identifier || false;
    if (!filter)
      return;

    const outImg = document.createElement("img");
    outImg.className = "fancybox-image";
    try {
      filterous.importImage(imgObj.$image[0])
          .applyInstaFilter(filter).renderHtml(outImg);
      imgObj.$image[0].parentNode.replaceChild(outImg, imgObj.$image[0]);
    } catch (e) {
      console.log(e);
    }
  }

  skipSpecifications() {
    // TODO ugly, requires better naming but the form is anonymous
    $('#sell-appraisal-upload').next('form').trigger('submit');
  }
}

export default Gallery;
