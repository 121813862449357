import Gallery from "./modules/gallery";
import SearchBase from "./modules/search-base";
import Wishlist from "./modules/wishlist";
import MediaFilter from "./modules/filter";
import AutoMediaFilter from "./modules/auto-media-filter";

const gallery = new Gallery();
const search_base = new SearchBase();
const wishlist = new Wishlist();
const media_filter = new MediaFilter();

new AutoMediaFilter();
