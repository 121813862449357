import noUiSlider from "nouislider";
import breakpoints from "./breakpoints";

const PRICE_FILTER_MIN = 300;
const PRICE_FILTER_MAX = 500000;

class SearchBase {
  constructor() {

    $(document).ready(() => {
      this.$filtersCollapsePanel = $('#search-filters-items');
      this.searchPanelEl = document.getElementById('search-filters');

      $("[data-ui-bind=category-list]").on("change",  (event) => window.location.href = event.target.value);
      $("[data-ui-hook=price-slider]").each((idx, element) => this.initSliders(element));
      this.initSwipers();
      $('body')
          .on('click', '.submenu-filter__dropdown', (event) => event.stopImmediatePropagation())
          .on('change', 'input[name=specifications]', () => {
            if (breakpoints.matchMediaLg('min').matches) {
              this.updateSpecificationSearch();
            }
          });
      $("[data-ui-trigger=search], [data-ui-trigger=apply-filters]")
          .on('click', () => this.updateSpecificationSearch());
      $("[name=keywords]").on('keypress', (event) => {
        if (event.which === 13) {
          this.updateSpecificationSearch();
        }
      });
      this.$filtersCollapsePanel
          .on('show.bs.collapse', () => this.searchPanelEl.classList
              .add('search-filters--filter--active'));
      this.$filtersCollapsePanel
          .on('hide.bs.collapse', (event) => this.searchPanelEl.classList
              .remove('search-filters--filter--active'));

      $('[data-ui-trigger=search-filters-close],[data-ui-trigger="search-filters-clear"]')
          .on('click', (event) => {
            event.preventDefault();
            event.stopImmediatePropagation();
            switch (event.target.dataset.uiTrigger) {
              case 'search-filters-clear':
                this.searchReset();
                this.pricesReset();
                this.checkboxesReset();
                this.updateSpecificationSearch();
              case 'search-filters-close':
                this.$filtersCollapsePanel.collapse('hide');
                this.searchPanelEl.classList.remove('search-filters--search--active');
                break;
            }
          });
      $('[data-ui-trigger=mobile-search]').on('click', () => {
        if (breakpoints.matchMediaLg('max').matches) {
          this.searchPanelEl.classList.add('search-filters--search--active');
        }
      });
    });

    this.displayCategoryName();
  }

  initSliders(element) {
    this.slider = noUiSlider.create(element, {
      start: [PRICE_FILTER_MIN, PRICE_FILTER_MAX],
      connect: true,
      range: {
        min: PRICE_FILTER_MIN,
        max: PRICE_FILTER_MAX
      }
    });

    this.slider.on('change', (values, handle, unencoded) => {
      $('[name=min_price]').val(values[0]);
      $('[name=max_price]').val(values[1]);
      if (breakpoints.matchMediaLg().matches) {
        this.updateSpecificationSearch();
      }
    });

    this.slider.on('update', (values, handle, unencoded) => {
      $('[data-bind=min_price]').text(parseInt(values[0]));
      $('[data-bind=max_price]').text(parseInt(values[1]));
    });

    $("[data-ui-trigger=price-reset]").on('click', () => {
      this.pricesReset();
      this.updateSpecificationSearch();
    });
  }

  initSwipers() {
    $("[data-ui-category-swiper]").each((idx, element) => this.initSwiper(element));
  }

  initSwiper(element) {
    var breakpointsConfig = {};
    var container = '#' + element.id;
    breakpoints.list.forEach((value, key) => {
      var slidesPerView = 4;
      switch (key) {
        case 'xs':
          slidesPerView = 1;
          break;
        case 'sm':
          slidesPerView = 2;
          break;
        case 'md':
          slidesPerView = 3;
          break;
      }
      breakpointsConfig[parseInt(value.replace('px'), 10)] = {slidesPerView};
    });

    var swiper = new Swiper(container + ' .swiper-container', {
      speed: 400,
      spaceBetween: 16,
      slidesPerView: 4,
      breakpoints: breakpointsConfig,
      navigation: {
        prevEl: container + ' .page-header__controls__button--prev',
        nextEl: container + ' .page-header__controls__button--next',
        disabledClass: 'page-header__controls__button--disabled'
      }
    });
  }

  updateSpecificationSearch() {
    var data = $('input', '#search-filters').serialize();

    return $.ajax({
        url: window.location.href,
        method: 'POST',
        data: data,
    }).done((data) => {
      $('#search-results-wrapper').html(data);
      this.$filtersCollapsePanel.collapse('hide');
      this.searchPanelEl.classList.remove('search-filters--search--active');
      this.initSwipers();
    });
  }

  searchReset() {
    const searchKeywordsEl = document.getElementById('search-keywords');
    if (searchKeywordsEl) searchKeywordsEl.value = "";
  }

  pricesReset() {
    this.slider.reset();
    $('[name=min_price]').val(0);
    $('[name=max_price]').val(0);
  }

  checkboxesReset() {
    $('[name="specifications"]').prop('checked', false);
  }

  displayCategoryName() {
    const categoryName = window.location.pathname.split('/').pop();
    const categoryNameEl = document.getElementById('category-name');
    if (categoryName && categoryNameEl) {
      categoryNameEl.innerText = decodeURI(categoryName);
    }
  }
}
export default SearchBase;
