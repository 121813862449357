const filters = [{
  identifier: "normal",
  label: "Original"
}];

const p = ["clarendon", "juno", "lark",
  "ludwig", "valencia", "xpro2", "lofi", "brooklyn", "amaro",
];

for (let f of p)
  filters.push({
    identifier: f,
    label: f.substr(0,1).toUpperCase() + f.substr(1)
  });

export default filters;
