import $ from "jquery";

// Applies a filter to every img with a data-media-filter="insta filter name" attribute
class AutoMediaFilter
{
  constructor() {
    $(document).ready(() => {
      document.querySelectorAll("img[data-media-filter]").forEach((el) => {
        let filter = el.dataset.mediaFilter;
        if (filter) {
          filterous.importImage(el).applyInstaFilter(filter).renderHtml(el);
        }
      });
    });
  }
}

export default AutoMediaFilter;
