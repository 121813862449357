class Breakpoints
{
  constructor() {
    this._list = new Map();
    this.autoLoad();
  }

  get documentRoot() {
    return getComputedStyle(document.documentElement);
  }

  get list() {
    return this._list;
  }

  /**
   * Automatically hooks CSS breakpoints variables and generate a list with.
   * @return {Void}
   */
  autoLoad() {
    const breakpointsMap = ['xs', 'sm', 'md', 'lg', 'xl'];

    for (let bp of breakpointsMap) {
      const breakpoint = this.documentRoot.getPropertyValue(`--breakpoint-${bp}`);
      this._list.set(bp, breakpoint);
    }
  }

  /**
   * Returns a MediaQueryList to check XS breakpoints
   * @return {MediaQueryList}
   * @param minMax
   */
  matchMediaXs(minMax = 'min') {
    return window.matchMedia(`only screen and (${minMax}-width: ${this._list.get('xs')})`);
  }

  /**
   * Returns a MediaQueryList to check SM breakpoints
   * @return {MediaQueryList}
   * @param minMax
   */
  matchMediaSm(minMax = 'min') {
    return window.matchMedia(`only screen and (${minMax}-width: ${this._list.get('sm')})`);
  }

  /**
   * Returns a MediaQueryList to check MD breakpoints
   * @return {MediaQueryList}
   * @param minMax
   */
  matchMediaMd(minMax = 'min') {
    return window.matchMedia(`only screen and (${minMax}-width: ${this._list.get('md')})`);
  }

  /**
   * Returns a MediaQueryList to check LG breakpoints
   * @return {MediaQueryList}
   * @param minMax
   */
  matchMediaLg(minMax = 'min') {
    return window.matchMedia(`only screen and (${minMax}-width: ${this._list.get('lg')})`);
  }

  /**
   * Returns a MediaQueryList to check XL breakpoints
   * @return {MediaQueryList}
   * @param minMax
   */
  matchMediaXl(minMax = 'min') {
    return window.matchMedia(`only screen and (${minMax}-width: ${this._list.get('xl')})`);
  }
}

const breakpoints = new Breakpoints();

export default breakpoints;